import { Box } from "@mui/material";

export default function ProductByEmerTypeScreen() {
  return (
    <main className="main-container">
      <Box
        sx={{
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <div>heloo</div>
      </Box>
    </main>
  );
}

// import { useState } from 'react';
import "../Dashboard/Dashboard.css";

import FullFeaturedCrudGrid from "./datagrid";

function ReportScreen() {
  return <FullFeaturedCrudGrid />;
}

export default ReportScreen;


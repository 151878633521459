import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/system";
import { useDebounce } from "../../components/utils/Hooks";
import { generateLastCycleRows } from "../../components/TatScreen/utils.tat";

export default function TatScreen2() {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState();
  const [productId, setProducId] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedProdName, setSelectedProdName] = useState("");
  const [productNames, setProductNames] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText);

  const hospitalId = localStorage.getItem("hospitalid");

  const getHistoryData = async (productId) => {
    if (productId === "" || !productId) return;

    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}historybyproductid/${productId}`;
    const { data } = await axios.get(url);
    const sortedHistory = data.documents;
    setLoading(false);
    // Add additional data extraction logic here
    sortedHistory.forEach((historyItem) => {
      // Extract additional data from historyItem
      const additionalData = {
        // Example: Extract quantity and price
        action: historyItem.type,
        quantity: historyItem.quantity,
      };

      // Add the additional data to the historyItem
      Object.assign(historyItem, additionalData);
    });

    setHistory(sortedHistory);
  };

  const getTotalHistory = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}historybyhospitalid/${hospitalId}`;
    const { data } = await axios.get(url);
    const sortedHistory = data.document;
    setLoading(false);
    // Add additional data extraction logic here
    sortedHistory.forEach((historyItem) => {
      // Extract additional data from historyItem
      const additionalData = {
        // Example: Extract quantity and price
        action: historyItem.type,
        quantity: historyItem.quantity,
      };

      // Add the additional data to the historyItem
      Object.assign(historyItem, additionalData);
    });

    setHistory(sortedHistory);
  };

  const getProductNames = async () => {
    setLoading(true);
    // Fetch product names and ids from the API
    const url = `${process.env.REACT_APP_BASE_URL}productsdata/${hospitalId}`;
    const { data } = await axios.get(url);

    // Map to an array of objects with name and _id, then sort alphabetically by name
    const sortedProducts = data.documents.reverse();
    setProductNames(sortedProducts);
    setLoading(false);
  };

  async function getLastHistory() {
    if (productId === "") {
      getTotalHistory();
      getProductNames();
    } else {
      getHistoryData(productId);
    }
  }

  const createEmptyRow6 = () => ({
    stockEntry: { date: "", quantity: "" }, // 1st column: Stock Entry
    bufferStock: { date: "", quantity: "" }, // 2nd column: Buffer Stock
    stockOut: { date: "", quantity: "" }, // 3rd column: Stock Out
    ordered: { date: "", quantity: "" },

    stockOrderPlaced: { date: "", quantity: "" }, // 4th column: Stock Entry of next row
  });
  // Helper function to create an empty row
  const createEmptyRow = () => ({
    stockEntry: { date: "-", quantity: "-" }, // 1st column: Stock Entry
    bufferStock: { date: "-", quantity: "-" }, // 2nd column: Buffer Stock
    stockOut: { date: "-", quantity: "-" }, // 3rd column: Stock Out
    ordered: { date: "-", quantity: "-" },

    stockOrderPlaced: { date: "-", quantity: "-" }, // 4th column: Stock Entry of next row
  });

  // Helper function to format the date in DD/MM/YYYY
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-IN"); // it should be en-IN
  };

  const generateTableRows = () => {
    if (!history) return [];

    const rows = [];
    let currentRow = createEmptyRow6(); // Initialize the first empty row

    history.forEach((entry, index) => {
      // console.log("Processing entry:", entry); // Log the entry being processed

      // Handle Stock Entry type
      if (entry.type === "Stock Entry") {
        if (!currentRow.stockEntry.date) {
          currentRow.stockEntry.date = formatDate(entry.date);
          currentRow.stockEntry.quantity = entry.quantity;
        } else {
          rows.push({ ...currentRow });
          currentRow = createEmptyRow();
          currentRow.stockEntry.date = formatDate(entry.date);
          currentRow.stockEntry.quantity = entry.quantity;
        }
      }

      // Handle Buffer Stock type
      if (entry.type === "Buffer Stock") {
        if (!currentRow.bufferStock.date) {
          currentRow.bufferStock.date = formatDate(entry.date);
          currentRow.bufferStock.quantity = entry.quantity;
        }
      }

      // Handle Stock Out type
      if (entry.type === "Stock Out") {
        currentRow.stockOut.date = formatDate(entry.date);
        currentRow.stockOut.quantity = entry.quantity;
        rows.push({ ...currentRow });
        currentRow = createEmptyRow(); // Reset for the next row
      }

      // Handle Ordered type
      if (entry.type === "Order") {
        currentRow.ordered.date = formatDate(entry.date);
        currentRow.ordered.quantity = entry.quantity;
      }

      // If we are processing the last entry, push the row
      if (index === history.length - 1) {
        rows.push({ ...currentRow });
      }
    });

    // Fill the stockOrderPlaced from the next row's Stock Entry
    for (let i = 0; i < rows.length - 1; i++) {
      if (rows[i + 1].stockEntry.date) {
        rows[i].stockOrderPlaced.date = rows[i + 1].stockEntry.date;
        rows[i].stockOrderPlaced.quantity = rows[i + 1].stockEntry.quantity;
      }
    }

    // console.log("Generated rows:", rows); // Log the generated rows for inspection
    return rows;
  };

  useEffect(() => {
    if (debouncedSearchText === "") return;
    async function fetchProducts() {
      const fetchedOptions = await axios.get(
        `${process.env.REACT_APP_BASE_URL}search/product`,
        {
          params: { term: debouncedSearchText, hospital: hospitalId },
        },
      );
      setOptions(fetchedOptions.data);
    }
    try {
      setListLoading(true);
      fetchProducts();
    } catch (err) {
      console.log(err);
    }

    setListLoading(false);
  }, [debouncedSearchText]);

  useEffect(() => {
    getLastHistory();
  }, [productId]);

  const newrows =
    productId === ""
      ? generateLastCycleRows(productNames, history, createEmptyRow)
      : generateTableRows();

  const StyledTableContainer = styled(TableContainer)({
    borderRadius: "8px",
    border: "solid 1px #e0e0e0",
    font: "Roboto",
    overflowX: "scroll",
    minHeight: 600,
  });

  const StyledTable = styled(Table)({
    minWidth: 1650,
    "& .MuiTableCell": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      minWidth: 120,
      fontFamily: "Poppins",
      color: "#2E718A",
    },
    "& .MuiTableCell-head": {
      color: "#2E718A",
      fontWeight: 500,
      borderRight: "1px solid #e0e0e0",
      fontFamily: "Poppins",
      backgroundColor: "#fafafa",
    },
  });

  const StyledTableCell = styled(TableCell)({
    fontFamily: "Poppins",
    fontWeight: 400,
    padding: "16px 10px",
    minWidth: 120,
    textAlign: "center",
    "&:last-child": {
      borderRight: "none",
    },
  });

  const StyledTableRow = styled(TableRow)({
    // "&:hover": {
    //   backgroundColor: "#f5f5f5",
    // },
  });

  return (
    <div>
      <section
        className="p-5 w-100"
        style={{
          backgroundColor: "#eeeee",
          borderRadius: ".5rem .5rem 0 0",
        }}
      >
        <div className="row">
          <div className="col">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body p-md-3">
                <div className="card-body p-md-3">
                  <Typography
                    variant="h4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px",
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      color: "black",
                      padding: "10px",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                    }}
                  >
                    TAT
                  </Typography>
                  <Autocomplete
                    sx={{ width: 400 }}
                    options={options}
                    loading={loading}
                    onChange={(_e, value) => {
                      if (value === null) {
                        setProducId("");
                        setSelectedProdName("");
                      } else {
                        setProducId(value._id);
                        setSelectedProdName(value.name);
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    onInputChange={(_event, newInputValue) => {
                      setSearchText(newInputValue);
                    }}
                    getOptionKey={(option) => option._id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Products"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {listLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  <Box sx={{ height: 700, width: "100%", marginTop: "20px" }}>
                    {loading ? (
                      <div
                        style={{
                          height: 700,
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <StyledTableContainer sx={{ overflowX: "scroll" }}>
                        <StyledTable>
                          <TableHead>
                            <StyledTableRow>
                              {productId !== "" && (
                                <StyledTableCell
                                  colSpan={1}
                                  rowSpan={2}
                                  sx={{ borderRight: "1px solid #e0e0e0" }}
                                >
                                  CYCLE NO
                                </StyledTableCell>
                              )}
                              <StyledTableCell
                                colSpan={1}
                                rowSpan={2}
                                sx={{ borderRight: "1px solid #e0e0e0" }}
                              >
                                PRODUCT NAME
                              </StyledTableCell>
                              <StyledTableCell colSpan={2} align="center">
                                STOCK ENTRY
                              </StyledTableCell>
                              <StyledTableCell colSpan={2} align="center">
                                BUFFER STOCK
                              </StyledTableCell>
                              <StyledTableCell colSpan={1} align="center">
                                STOCK OUT
                              </StyledTableCell>
                              <StyledTableCell colSpan={2} align="center">
                                ORDERED
                              </StyledTableCell>
                              <StyledTableCell colSpan={2} align="center">
                                STOCK REFILL
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>DATE</StyledTableCell>
                              <StyledTableCell>QUANTITY</StyledTableCell>
                              <StyledTableCell>DATE</StyledTableCell>
                              <StyledTableCell>QUANTITY</StyledTableCell>
                              <StyledTableCell>DATE</StyledTableCell>
                              {/* <StyledTableCell>QUANTITY</StyledTableCell> */}
                              <StyledTableCell>DATE</StyledTableCell>
                              <StyledTableCell>QUANTITY</StyledTableCell>
                              <StyledTableCell>DATE</StyledTableCell>
                              <StyledTableCell>QUANTITY</StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {newrows.map((row, index) => (
                              <StyledTableRow key={index}>
                                {productId !== "" && (
                                  <StyledTableCell>{index + 1}</StyledTableCell>
                                )}
                                <StyledTableCell>
                                  {productId === ""
                                    ? row.productName
                                    : selectedProdName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.stockEntry.date}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.stockEntry.quantity}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.bufferStock.date}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.bufferStock.quantity}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.stockOut.date}
                                </StyledTableCell>
                                {/* <StyledTableCell>
                                  {row.stockOut.quantity}
                                </StyledTableCell> */}
                                <StyledTableCell>
                                  {row.ordered.date}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.ordered.quantity}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.stockOrderPlaced.date}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.stockOrderPlaced.quantity}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </StyledTable>
                      </StyledTableContainer>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
